import * as React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

export default function FooterContentTwo() {
  return (
          <>
          <div className="mt-12 sm:mt-16 md:mt-0 ">
            <h2 className="text-xl font-bold  sm:text-xl sm:tracking-tight text-w-blue">Unser Netzwerk</h2>
            <div className="mt-3">
              <p className="text-lg sm:text-base text-gray-500">
              Analysten, Architekten, Bauplanungsingenieure, Regierungsbaumeister, Bautechniker, Maurer, Gipser, Schreiner, Küchenbauer, Trockenbauer, Dachdecker, Fliesenleger, Elektriker, Sanitär & Heizungsmonteure, Reinigungsexperten und natürlich auch ausreichend Helfer fürs Grobe.
              </p>
            </div>
            
          </div>
    
          </>
  )
}
