import React from "react";

export default function LogoOne(){
    return (<>

<svg id="Ebene_2" className="hidden md:inline lg:hidden" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.67 41.63" width="32mm">
  <g id="Ebene_1-2" data-name="Ebene 1">
    <path d="m0,7.67h7.51v10.78c0,.84.09,1.43.26,1.76.17.33.49.49.94.49.84,0,1.27-.75,1.27-2.25V7.67h7.51v10.78c0,.84.09,1.43.27,1.76s.5.49.95.49c.83,0,1.25-.75,1.25-2.25V7.67h7.51v12.33c0,2.25-.76,4.16-2.27,5.73-1.51,1.57-3.35,2.36-5.5,2.36s-4.22-.94-5.98-2.81c-1.95,1.87-3.92,2.81-5.9,2.81-2.5,0-4.51-.91-6.02-2.73-1.19-1.43-1.79-3.5-1.79-6.2V7.67Z" fill="#0a4778"/>
    <path d="m31.57,7.67h7.51v11.31c0,1.14.47,1.71,1.43,1.71s1.41-.57,1.41-1.71V7.67h7.51v11.16c0,2.6-.86,4.79-2.59,6.58-1.73,1.79-3.84,2.68-6.35,2.68-2.85,0-5.15-1.04-6.89-3.11-1.35-1.62-2.03-3.87-2.03-6.75V7.67Z" fill="#0a4778"/>
    <path d="m90.17,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#be1421"/>
    <path d="m104.72,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#1a1a18"/>
    <path d="m123.72,7.65l-6.31,12.3h7.25v7.97h-19.81l6.27-12.35h-6.51v-7.93h19.1Z" fill="#1a1a18"/>
    <path d="m71.18,10.08s-.03-.03-.04-.04c-2.01-2.03-4.44-2.97-7.26-3.05-1.21-.04-2.25.12-3.15.49V0h-7.51v16.69c0,3.07.8,5.56,2.41,7.49.92,1.11,2.1,1.99,3.52,2.65.19.09.38.15.57.23.15.07.31.13.47.19.02,0,.04.01.05.02,1.14.43,2.38.68,3.67.68,5.75,0,10.41-4.66,10.41-10.41,0-2.92-1.21-5.56-3.14-7.45Zm-7.31,10.06c-1.66,0-3-1.34-3-3s1.34-3,3-3,3,1.34,3,3-1.34,3-3,3Z" fill="#be1421"/>
    <g>
      <path d="m4.54,38.07h.02l.78-4.3h.88l-1.24,6.04h-.78l-1.05-4.29h-.03l-1.06,4.29h-.78L.06,33.77h.89l.79,4.29h.02l1.02-4.29h.73l1.03,4.3Z" fill="#1a1a18"/>
      <path d="m8.56,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m11.85,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m16.3,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m20.66,34.71l-.36,1.16h-.46l.02-1.1v-.99h.8v.94Z" fill="#1a1a18"/>
      <path d="m23.77,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m29.43,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m32.65,39.81c-.04-.14-.06-.26-.09-.36-.02-.11-.03-.21-.04-.32-.12.22-.28.4-.47.55-.2.15-.42.22-.67.22-.4,0-.71-.12-.92-.36-.21-.24-.32-.56-.32-.98s.15-.76.45-1c.3-.24.71-.37,1.24-.37h.69v-.46c0-.27-.06-.49-.18-.64-.12-.15-.3-.23-.52-.23-.2,0-.37.07-.49.2-.12.13-.18.3-.18.51h-.8s0-.02,0-.02c-.02-.35.12-.66.41-.92.29-.27.66-.4,1.13-.4s.81.13,1.08.39.42.63.42,1.12v2.08c0,.17.01.34.03.5s.06.32.1.48h-.85Zm-1.09-.56c.23,0,.43-.07.61-.21s.29-.31.35-.52v-.8h-.7c-.26,0-.46.08-.61.25-.15.17-.22.36-.22.6,0,.21.05.37.15.5s.24.19.42.19Z" fill="#1a1a18"/>
      <path d="m36.68,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m40.09,39.26c.21,0,.38-.08.52-.24.14-.16.21-.38.21-.65h.76v.02c.02.42-.12.78-.42,1.07-.3.29-.65.44-1.07.44-.55,0-.98-.21-1.28-.63s-.45-.96-.45-1.62v-.16c0-.65.15-1.19.45-1.62s.73-.64,1.28-.64c.45,0,.81.15,1.09.46s.42.7.41,1.18v.02h-.77c0-.3-.07-.54-.2-.73-.13-.19-.31-.28-.53-.28-.32,0-.54.15-.68.46s-.21.69-.21,1.15v.16c0,.47.07.86.21,1.16.14.3.37.45.68.45Z" fill="#1a1a18"/>
      <path d="m43.46,35.92c.13-.22.28-.38.47-.5.19-.12.39-.18.62-.18.41,0,.73.15.96.45.23.3.34.76.34,1.38v2.75h-.85v-2.76c0-.41-.06-.71-.18-.89-.12-.18-.29-.27-.53-.27-.19,0-.36.05-.5.14-.14.09-.25.23-.34.39v3.38h-.85v-6.47h.85v2.59Z" fill="#1a1a18"/>
      <path d="m52.33,38.35h.02l.57-3.03h.85l-1.05,4.49h-.7l-.83-2.91h-.02l-.81,2.91h-.7l-1.05-4.49h.84l.59,2.99h.03l.82-2.99h.61l.83,3.03Z" fill="#1a1a18"/>
      <path d="m55.55,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m58.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m60.81,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.21,0,.4.05.57.14.17.1.31.23.44.41v-2.46h.84v6.47h-.7l-.09-.54c-.12.2-.28.36-.46.47s-.39.16-.62.16c-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.84.09c0,.47.07.84.21,1.12s.36.42.66.42c.19,0,.35-.05.48-.15s.24-.24.32-.42v-2.18c-.08-.18-.19-.33-.32-.44s-.29-.16-.47-.16c-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m67.02,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m71.47,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m74.15,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.23,0,.44.06.62.17.18.11.33.27.45.48l.08-.56h.71v4.5c0,.58-.15,1.02-.46,1.33-.31.31-.74.47-1.3.47-.19,0-.4-.03-.61-.09s-.41-.14-.59-.24l.14-.63c.15.09.32.15.5.2.18.05.36.07.56.07.32,0,.55-.09.7-.28.15-.19.22-.47.22-.85v-.44c-.12.17-.27.31-.43.39-.17.09-.36.13-.58.13-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.85.09c0,.47.07.85.21,1.12.14.28.36.42.66.42.19,0,.35-.05.48-.15s.24-.23.32-.41v-2.21c-.08-.18-.19-.33-.32-.43-.13-.11-.29-.16-.48-.16-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m81.15,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.06.82.17,1.01s.28.29.51.29c.21,0,.38-.05.53-.15.15-.1.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m84.26,34.21v1.12h.7v.6h-.7v2.67c0,.23.04.4.11.5.07.1.16.15.28.15.05,0,.09,0,.13-.01.04,0,.09-.02.15-.04l.09.58c-.08.04-.17.07-.27.09s-.2.04-.31.04c-.32,0-.58-.1-.75-.31-.18-.21-.27-.54-.27-.99v-2.67h-.59v-.6h.59v-1.12h.85Z" fill="#1a1a18"/>
      <path d="m89.98,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m93.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54c.27.36.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12.17.29.42.43.76.43.24,0,.44-.04.61-.11.17-.07.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m96.86,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m98.88,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m105.2,35.32l.05.61c.12-.22.28-.39.47-.51.19-.12.42-.18.67-.18s.46.07.65.21.32.34.41.62c.12-.26.28-.46.48-.61.2-.15.43-.22.7-.22.39,0,.7.16.92.48.22.32.33.8.33,1.45v2.65h-.85v-2.66c0-.47-.06-.79-.17-.98s-.29-.28-.52-.28c-.19,0-.36.07-.49.22-.13.14-.23.33-.29.57,0,.05,0,.1,0,.16,0,.05,0,.11,0,.16v2.81h-.84v-2.66c0-.45-.06-.77-.18-.97-.12-.2-.29-.29-.52-.29-.18,0-.34.05-.46.15-.13.1-.23.24-.3.41v3.36h-.85v-4.49h.78Z" fill="#1a1a18"/>
      <path d="m113.5,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.05.82.17,1.01.11.19.28.29.51.29.21,0,.38-.05.53-.15s.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m117.64,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.08-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.36.49.36.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63s.35.19.58.19.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m121.66,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58s.35.49.35.82c0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.34.19.57.19s.4-.06.53-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m124.49,39.81h-.85v-.84h.85v.84Z" fill="#1a1a18"/>
    </g>
  </g>
</svg>

<svg id="Ebene_2" className="hidden lg:inline" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.28 41.63" width="59mm">
  <g id="Ebene_1-2" data-name="Ebene 1">
    <path d="m0,7.67h7.51v10.78c0,.84.09,1.43.26,1.76.17.33.49.49.94.49.84,0,1.27-.75,1.27-2.25V7.67h7.51v10.78c0,.84.09,1.43.27,1.76s.5.49.95.49c.83,0,1.25-.75,1.25-2.25V7.67h7.51v12.33c0,2.25-.76,4.16-2.27,5.73-1.51,1.57-3.35,2.36-5.5,2.36s-4.22-.94-5.98-2.81c-1.95,1.87-3.92,2.81-5.9,2.81-2.5,0-4.51-.91-6.02-2.73-1.19-1.43-1.79-3.5-1.79-6.2V7.67Z" fill="#0a4778"/>
    <path d="m31.57,7.67h7.51v11.31c0,1.14.47,1.71,1.43,1.71s1.41-.57,1.41-1.71V7.67h7.51v11.16c0,2.6-.86,4.79-2.59,6.58-1.73,1.79-3.84,2.68-6.35,2.68-2.85,0-5.15-1.04-6.89-3.11-1.35-1.62-2.03-3.87-2.03-6.75V7.67Z" fill="#0a4778"/>
    <path d="m90.17,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#be1421"/>
    <path d="m104.72,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#1a1a18"/>
    <path d="m123.72,7.65l-6.31,12.3h7.25v7.97h-19.81l6.27-12.35h-6.51v-7.93h19.1Z" fill="#1a1a18"/>
    <path d="m71.18,10.08s-.03-.03-.04-.04c-2.01-2.03-4.44-2.97-7.26-3.05-1.21-.04-2.25.12-3.15.49V0h-7.51v16.69c0,3.07.8,5.56,2.41,7.49.92,1.11,2.1,1.99,3.52,2.65.19.09.38.15.57.23.15.07.31.13.47.19.02,0,.04.01.05.02,1.14.43,2.38.68,3.67.68,5.75,0,10.41-4.66,10.41-10.41,0-2.92-1.21-5.56-3.14-7.45Zm-7.31,10.06c-1.66,0-3-1.34-3-3s1.34-3,3-3,3,1.34,3,3-1.34,3-3,3Z" fill="#be1421"/>
    <g>
      <path d="m136.25,10.86h.02l.6-3.23h1.17l-1.14,4.98h-1.09l-.84-3.07h-.02l-.83,3.07h-1.09l-1.15-4.98h1.17l.6,3.23h.02s.84-3.23.84-3.23h.87l.85,3.23Z" fill="#1a1a18"/>
      <path d="m141.09,11.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m145.77,11.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m150.4,11.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m155.37,10.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m157.8,10.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.31.09.53.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54s-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.54-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m163.29,10.92h-2.01v-.89h2.01v.89Z" fill="#1a1a18"/>
      <path d="m170.16,7.64v3.23c0,.57-.19,1.02-.57,1.34-.38.32-.87.48-1.48.48s-1.11-.16-1.49-.48c-.38-.32-.57-.77-.57-1.34v-3.23h1.16v3.23c0,.31.08.54.24.7.16.16.38.24.66.24s.5-.08.65-.23c.16-.16.23-.39.23-.7v-3.23h1.16Z" fill="#1a1a18"/>
      <path d="m175.37,12.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m176.45,12.61v-4.98h1.82c.59,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32.17-.21.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82-.17-.21-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m132.24,21.61v-4.98h1.63c.62,0,1.1.11,1.44.34.35.23.52.57.52,1.02,0,.24-.05.45-.16.63s-.28.32-.51.42c.3.06.52.2.66.4.14.2.21.43.21.69,0,.48-.16.85-.49,1.1-.33.25-.79.37-1.4.37h-1.9Zm1.16-2.9h.46c.28,0,.49-.05.62-.14s.21-.24.21-.43c0-.21-.07-.37-.2-.46-.13-.1-.33-.15-.61-.15h-.48v1.19Zm0,.77v1.25h.74c.25,0,.43-.05.55-.15s.18-.25.18-.45-.05-.37-.16-.48c-.11-.11-.29-.17-.53-.17h-.78Z" fill="#1a1a18"/>
      <path d="m138.1,19.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.3.09.52.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54-.12-.13-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.53-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m144.45,20.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m150.81,21.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m151.89,21.61v-4.98h1.82c.58,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32s.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82s-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m159.27,20.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64s-.45-.55-.45-.98.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35.36.2.7.33c.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.8.37-1.36.37-1.04-.13-1.43-.4-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62.14.12.36.18.66.18.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m165.13,19.94v.02c.02.57-.14,1-.47,1.29s-.8.44-1.4.44-1.11-.19-1.48-.56c-.37-.38-.56-.87-.56-1.48v-1.04c0-.61.18-1.1.54-1.47.36-.38.83-.57,1.42-.57.63,0,1.12.15,1.46.44.35.29.51.72.5,1.28v.02h-1.13c0-.31-.06-.52-.19-.66-.13-.13-.34-.2-.64-.2-.26,0-.46.1-.6.31-.14.21-.21.49-.21.83v1.05c0,.35.08.63.23.84.15.21.37.31.66.31.26,0,.46-.07.57-.2.12-.13.18-.35.18-.66h1.12Z" fill="#1a1a18"/>
      <path d="m170.18,21.61h-1.15v-2h-1.84v2h-1.16v-4.98h1.16v2.08h1.84v-2.08h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m173.9,20.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m176.11,21.61v-4.98h1.82c.58,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32s.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82s-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m183.85,19.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m189.28,21.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m134.81,29.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64s-.45-.55-.45-.98.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35.36.2.7.33c.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03c-.34.25-.8.37-1.36.37s-1.04-.13-1.43-.4-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62.14.12.36.18.66.18.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m139.45,29.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m145.81,30.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m148.08,30.61h-1.15v-4.98h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m152.11,28.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m154.54,28.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.31.09.53.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54s-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.54-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m162.11,25.64v3.23c0,.57-.19,1.02-.57,1.34-.38.32-.87.48-1.48.48s-1.11-.16-1.49-.48c-.38-.32-.57-.77-.57-1.34v-3.23h1.16v3.23c0,.31.08.54.24.7.16.16.38.24.66.24s.5-.08.65-.23.23-.39.23-.7v-3.23h1.16Z" fill="#1a1a18"/>
      <path d="m167.31,30.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m172.31,29.93c-.17.21-.42.39-.73.54s-.72.22-1.21.22c-.62,0-1.12-.19-1.5-.57-.38-.38-.58-.87-.58-1.47v-1.04c0-.6.19-1.09.56-1.47.37-.38.86-.57,1.45-.57.64,0,1.12.14,1.44.43.33.28.48.67.47,1.16v.02h-1.09c0-.24-.07-.42-.2-.54-.13-.12-.33-.18-.59-.18s-.49.11-.65.32-.25.49-.25.83v1.05c0,.35.08.62.25.84.16.21.39.32.68.32.21,0,.38-.02.5-.05s.22-.08.29-.14v-.89h-.88v-.77h2.04v1.97Z" fill="#1a1a18"/>
      <path d="m175.83,29.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m133.45,38.73h2.05v.89h-3.42v-.52l2.01-3.57h-2.02v-.89h3.4v.5l-2.02,3.59Z" fill="#1a1a18"/>
      <path d="m139.26,37.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m144.69,39.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m149.22,35.53h-1.32v4.09h-1.16v-4.09h-1.29v-.89h3.76v.89Z" fill="#1a1a18"/>
      <path d="m151.14,37.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.3.09.52.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54-.12-.13-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.53-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m157.49,38.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m160.85,38.73h2.14v.89h-3.29v-4.98h1.16v4.09Z" fill="#1a1a18"/>
      <path d="m166.74,37.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
    </g>
    <g>
      <path d="m4.54,38.07h.02l.78-4.3h.88l-1.24,6.04h-.78l-1.05-4.29h-.03l-1.06,4.29h-.78L.06,33.77h.89l.79,4.29h.02l1.02-4.29h.73l1.03,4.3Z" fill="#1a1a18"/>
      <path d="m8.56,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m11.85,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m16.3,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m20.66,34.71l-.36,1.16h-.46l.02-1.1v-.99h.8v.94Z" fill="#1a1a18"/>
      <path d="m23.77,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m29.43,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m32.65,39.81c-.04-.14-.06-.26-.09-.36-.02-.11-.03-.21-.04-.32-.12.22-.28.4-.47.55-.2.15-.42.22-.67.22-.4,0-.71-.12-.92-.36-.21-.24-.32-.56-.32-.98s.15-.76.45-1c.3-.24.71-.37,1.24-.37h.69v-.46c0-.27-.06-.49-.18-.64-.12-.15-.3-.23-.52-.23-.2,0-.37.07-.49.2-.12.13-.18.3-.18.51h-.8s0-.02,0-.02c-.02-.35.12-.66.41-.92.29-.27.66-.4,1.13-.4s.81.13,1.08.39.42.63.42,1.12v2.08c0,.17.01.34.03.5s.06.32.1.48h-.85Zm-1.09-.56c.23,0,.43-.07.61-.21s.29-.31.35-.52v-.8h-.7c-.26,0-.46.08-.61.25-.15.17-.22.36-.22.6,0,.21.05.37.15.5s.24.19.42.19Z" fill="#1a1a18"/>
      <path d="m36.68,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m40.09,39.26c.21,0,.38-.08.52-.24.14-.16.21-.38.21-.65h.76v.02c.02.42-.12.78-.42,1.07-.3.29-.65.44-1.07.44-.55,0-.98-.21-1.28-.63s-.45-.96-.45-1.62v-.16c0-.65.15-1.19.45-1.62s.73-.64,1.28-.64c.45,0,.81.15,1.09.46s.42.7.41,1.18v.02h-.77c0-.3-.07-.54-.2-.73-.13-.19-.31-.28-.53-.28-.32,0-.54.15-.68.46s-.21.69-.21,1.15v.16c0,.47.07.86.21,1.16.14.3.37.45.68.45Z" fill="#1a1a18"/>
      <path d="m43.46,35.92c.13-.22.28-.38.47-.5.19-.12.39-.18.62-.18.41,0,.73.15.96.45.23.3.34.76.34,1.38v2.75h-.85v-2.76c0-.41-.06-.71-.18-.89-.12-.18-.29-.27-.53-.27-.19,0-.36.05-.5.14-.14.09-.25.23-.34.39v3.38h-.85v-6.47h.85v2.59Z" fill="#1a1a18"/>
      <path d="m52.33,38.35h.02l.57-3.03h.85l-1.05,4.49h-.7l-.83-2.91h-.02l-.81,2.91h-.7l-1.05-4.49h.84l.59,2.99h.03l.82-2.99h.61l.83,3.03Z" fill="#1a1a18"/>
      <path d="m55.55,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m58.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m60.81,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.21,0,.4.05.57.14.17.1.31.23.44.41v-2.46h.84v6.47h-.7l-.09-.54c-.12.2-.28.36-.46.47s-.39.16-.62.16c-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.84.09c0,.47.07.84.21,1.12s.36.42.66.42c.19,0,.35-.05.48-.15s.24-.24.32-.42v-2.18c-.08-.18-.19-.33-.32-.44s-.29-.16-.47-.16c-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m67.02,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m71.47,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m74.15,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.23,0,.44.06.62.17.18.11.33.27.45.48l.08-.56h.71v4.5c0,.58-.15,1.02-.46,1.33-.31.31-.74.47-1.3.47-.19,0-.4-.03-.61-.09s-.41-.14-.59-.24l.14-.63c.15.09.32.15.5.2.18.05.36.07.56.07.32,0,.55-.09.7-.28.15-.19.22-.47.22-.85v-.44c-.12.17-.27.31-.43.39-.17.09-.36.13-.58.13-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.85.09c0,.47.07.85.21,1.12.14.28.36.42.66.42.19,0,.35-.05.48-.15s.24-.23.32-.41v-2.21c-.08-.18-.19-.33-.32-.43-.13-.11-.29-.16-.48-.16-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m81.15,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.06.82.17,1.01s.28.29.51.29c.21,0,.38-.05.53-.15.15-.1.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m84.26,34.21v1.12h.7v.6h-.7v2.67c0,.23.04.4.11.5.07.1.16.15.28.15.05,0,.09,0,.13-.01.04,0,.09-.02.15-.04l.09.58c-.08.04-.17.07-.27.09s-.2.04-.31.04c-.32,0-.58-.1-.75-.31-.18-.21-.27-.54-.27-.99v-2.67h-.59v-.6h.59v-1.12h.85Z" fill="#1a1a18"/>
      <path d="m89.98,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m93.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54c.27.36.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12.17.29.42.43.76.43.24,0,.44-.04.61-.11.17-.07.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m96.86,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m98.88,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m105.2,35.32l.05.61c.12-.22.28-.39.47-.51.19-.12.42-.18.67-.18s.46.07.65.21.32.34.41.62c.12-.26.28-.46.48-.61.2-.15.43-.22.7-.22.39,0,.7.16.92.48.22.32.33.8.33,1.45v2.65h-.85v-2.66c0-.47-.06-.79-.17-.98s-.29-.28-.52-.28c-.19,0-.36.07-.49.22-.13.14-.23.33-.29.57,0,.05,0,.1,0,.16,0,.05,0,.11,0,.16v2.81h-.84v-2.66c0-.45-.06-.77-.18-.97-.12-.2-.29-.29-.52-.29-.18,0-.34.05-.46.15-.13.1-.23.24-.3.41v3.36h-.85v-4.49h.78Z" fill="#1a1a18"/>
      <path d="m113.5,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.05.82.17,1.01.11.19.28.29.51.29.21,0,.38-.05.53-.15s.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m117.64,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.08-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.36.49.36.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63s.35.19.58.19.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m121.66,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58s.35.49.35.82c0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.34.19.57.19s.4-.06.53-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m124.49,39.81h-.85v-.84h.85v.84Z" fill="#1a1a18"/>
    </g>
  </g>
</svg>     
<svg id="Ebene_2" className="md:hidden" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.28 41.63" width="44mm">
  <g id="Ebene_1-2" data-name="Ebene 1">
    <path d="m0,7.67h7.51v10.78c0,.84.09,1.43.26,1.76.17.33.49.49.94.49.84,0,1.27-.75,1.27-2.25V7.67h7.51v10.78c0,.84.09,1.43.27,1.76s.5.49.95.49c.83,0,1.25-.75,1.25-2.25V7.67h7.51v12.33c0,2.25-.76,4.16-2.27,5.73-1.51,1.57-3.35,2.36-5.5,2.36s-4.22-.94-5.98-2.81c-1.95,1.87-3.92,2.81-5.9,2.81-2.5,0-4.51-.91-6.02-2.73-1.19-1.43-1.79-3.5-1.79-6.2V7.67Z" fill="#0a4778"/>
    <path d="m31.57,7.67h7.51v11.31c0,1.14.47,1.71,1.43,1.71s1.41-.57,1.41-1.71V7.67h7.51v11.16c0,2.6-.86,4.79-2.59,6.58-1.73,1.79-3.84,2.68-6.35,2.68-2.85,0-5.15-1.04-6.89-3.11-1.35-1.62-2.03-3.87-2.03-6.75V7.67Z" fill="#0a4778"/>
    <path d="m90.17,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#be1421"/>
    <path d="m104.72,7.67v7.91c-1.06,0-1.78.2-2.18.59s-.65,1.17-.75,2.34c-.35,3.87-1.85,6.6-4.5,8.19-1.41.83-3.4,1.25-5.98,1.25h-1.04v-7.95h.34c1.2,0,2.06-.25,2.58-.75s.81-1.38.89-2.64c.11-2.05.3-3.49.57-4.32s.83-1.65,1.68-2.47c1.47-1.43,3.51-2.15,6.12-2.15h2.27Z" fill="#1a1a18"/>
    <path d="m123.72,7.65l-6.31,12.3h7.25v7.97h-19.81l6.27-12.35h-6.51v-7.93h19.1Z" fill="#1a1a18"/>
    <path d="m71.18,10.08s-.03-.03-.04-.04c-2.01-2.03-4.44-2.97-7.26-3.05-1.21-.04-2.25.12-3.15.49V0h-7.51v16.69c0,3.07.8,5.56,2.41,7.49.92,1.11,2.1,1.99,3.52,2.65.19.09.38.15.57.23.15.07.31.13.47.19.02,0,.04.01.05.02,1.14.43,2.38.68,3.67.68,5.75,0,10.41-4.66,10.41-10.41,0-2.92-1.21-5.56-3.14-7.45Zm-7.31,10.06c-1.66,0-3-1.34-3-3s1.34-3,3-3,3,1.34,3,3-1.34,3-3,3Z" fill="#be1421"/>
    <g>
      <path d="m136.25,10.86h.02l.6-3.23h1.17l-1.14,4.98h-1.09l-.84-3.07h-.02l-.83,3.07h-1.09l-1.15-4.98h1.17l.6,3.23h.02s.84-3.23.84-3.23h.87l.85,3.23Z" fill="#1a1a18"/>
      <path d="m141.09,11.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m145.77,11.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m150.4,11.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m155.37,10.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m157.8,10.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.31.09.53.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54s-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.54-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m163.29,10.92h-2.01v-.89h2.01v.89Z" fill="#1a1a18"/>
      <path d="m170.16,7.64v3.23c0,.57-.19,1.02-.57,1.34-.38.32-.87.48-1.48.48s-1.11-.16-1.49-.48c-.38-.32-.57-.77-.57-1.34v-3.23h1.16v3.23c0,.31.08.54.24.7.16.16.38.24.66.24s.5-.08.65-.23c.16-.16.23-.39.23-.7v-3.23h1.16Z" fill="#1a1a18"/>
      <path d="m175.37,12.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m176.45,12.61v-4.98h1.82c.59,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32.17-.21.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82-.17-.21-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m132.24,21.61v-4.98h1.63c.62,0,1.1.11,1.44.34.35.23.52.57.52,1.02,0,.24-.05.45-.16.63s-.28.32-.51.42c.3.06.52.2.66.4.14.2.21.43.21.69,0,.48-.16.85-.49,1.1-.33.25-.79.37-1.4.37h-1.9Zm1.16-2.9h.46c.28,0,.49-.05.62-.14s.21-.24.21-.43c0-.21-.07-.37-.2-.46-.13-.1-.33-.15-.61-.15h-.48v1.19Zm0,.77v1.25h.74c.25,0,.43-.05.55-.15s.18-.25.18-.45-.05-.37-.16-.48c-.11-.11-.29-.17-.53-.17h-.78Z" fill="#1a1a18"/>
      <path d="m138.1,19.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.3.09.52.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54-.12-.13-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.53-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m144.45,20.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m150.81,21.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m151.89,21.61v-4.98h1.82c.58,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32s.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82s-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m159.27,20.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64s-.45-.55-.45-.98.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35.36.2.7.33c.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.8.37-1.36.37-1.04-.13-1.43-.4-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62.14.12.36.18.66.18.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m165.13,19.94v.02c.02.57-.14,1-.47,1.29s-.8.44-1.4.44-1.11-.19-1.48-.56c-.37-.38-.56-.87-.56-1.48v-1.04c0-.61.18-1.1.54-1.47.36-.38.83-.57,1.42-.57.63,0,1.12.15,1.46.44.35.29.51.72.5,1.28v.02h-1.13c0-.31-.06-.52-.19-.66-.13-.13-.34-.2-.64-.2-.26,0-.46.1-.6.31-.14.21-.21.49-.21.83v1.05c0,.35.08.63.23.84.15.21.37.31.66.31.26,0,.46-.07.57-.2.12-.13.18-.35.18-.66h1.12Z" fill="#1a1a18"/>
      <path d="m170.18,21.61h-1.15v-2h-1.84v2h-1.16v-4.98h1.16v2.08h1.84v-2.08h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m173.9,20.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m176.11,21.61v-4.98h1.82c.58,0,1.08.19,1.47.57.39.38.59.87.59,1.47v.89c0,.6-.2,1.09-.59,1.47-.39.38-.88.57-1.47.57h-1.82Zm1.16-4.09v3.2h.67c.26,0,.48-.11.65-.32s.25-.49.25-.83v-.9c0-.33-.08-.61-.25-.82s-.39-.32-.65-.32h-.67Z" fill="#1a1a18"/>
      <path d="m183.85,19.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m189.28,21.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m134.81,29.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64s-.45-.55-.45-.98.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35.36.2.7.33c.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03c-.34.25-.8.37-1.36.37s-1.04-.13-1.43-.4-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62.14.12.36.18.66.18.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m139.45,29.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m145.81,30.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m148.08,30.61h-1.15v-4.98h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m152.11,28.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m154.54,28.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.31.09.53.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54s-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.54-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m162.11,25.64v3.23c0,.57-.19,1.02-.57,1.34-.38.32-.87.48-1.48.48s-1.11-.16-1.49-.48c-.38-.32-.57-.77-.57-1.34v-3.23h1.16v3.23c0,.31.08.54.24.7.16.16.38.24.66.24s.5-.08.65-.23.23-.39.23-.7v-3.23h1.16Z" fill="#1a1a18"/>
      <path d="m167.31,30.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m172.31,29.93c-.17.21-.42.39-.73.54s-.72.22-1.21.22c-.62,0-1.12-.19-1.5-.57-.38-.38-.58-.87-.58-1.47v-1.04c0-.6.19-1.09.56-1.47.37-.38.86-.57,1.45-.57.64,0,1.12.14,1.44.43.33.28.48.67.47,1.16v.02h-1.09c0-.24-.07-.42-.2-.54-.13-.12-.33-.18-.59-.18s-.49.11-.65.32-.25.49-.25.83v1.05c0,.35.08.62.25.84.16.21.39.32.68.32.21,0,.38-.02.5-.05s.22-.08.29-.14v-.89h-.88v-.77h2.04v1.97Z" fill="#1a1a18"/>
      <path d="m175.83,29.3c0-.18-.06-.31-.17-.41s-.33-.2-.63-.3c-.62-.2-1.08-.41-1.38-.64-.3-.23-.45-.55-.45-.98s.17-.75.52-1.01.79-.39,1.33-.39c.57,0,1.02.13,1.37.4.35.27.51.64.5,1.12v.02h-1.13c0-.23-.06-.41-.19-.51-.13-.11-.32-.16-.58-.16-.21,0-.38.05-.5.15-.12.1-.18.23-.18.38s.06.26.19.35c.12.09.36.2.7.33.58.17,1.01.38,1.31.62.3.25.44.58.44,1.01s-.17.78-.51,1.03-.79.37-1.36.37-1.04-.13-1.43-.4c-.39-.27-.58-.68-.57-1.25v-.02h1.13c0,.29.07.5.21.62s.36.18.65.18c.24,0,.42-.05.54-.14.12-.1.18-.22.18-.38Z" fill="#1a1a18"/>
      <path d="m133.45,38.73h2.05v.89h-3.42v-.52l2.01-3.57h-2.02v-.89h3.4v.5l-2.02,3.59Z" fill="#1a1a18"/>
      <path d="m139.26,37.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
      <path d="m144.69,39.61h-1.15l-1.82-3.13h-.02v3.13h-1.16v-4.98h1.16l1.82,3.13h.02v-3.13h1.15v4.98Z" fill="#1a1a18"/>
      <path d="m149.22,35.53h-1.32v4.09h-1.16v-4.09h-1.29v-.89h3.76v.89Z" fill="#1a1a18"/>
      <path d="m151.14,37.71v1.91h-1.16v-4.98h1.73c.58,0,1.03.13,1.37.4.34.27.51.63.51,1.09,0,.26-.07.48-.2.66-.14.19-.34.34-.6.45.3.09.52.23.66.43.13.2.2.45.2.75v.31c0,.13.02.28.06.44.04.17.11.29.2.36v.07h-1.19c-.09-.07-.15-.2-.18-.37s-.04-.34-.04-.5v-.3c0-.24-.06-.42-.19-.54-.12-.13-.31-.19-.55-.19h-.62Zm0-.89h.58c.23,0,.41-.05.53-.16.12-.11.18-.26.18-.46s-.06-.37-.19-.49c-.13-.12-.3-.19-.53-.19h-.58v1.29Z" fill="#1a1a18"/>
      <path d="m157.49,38.65h-1.48l-.29.97h-1.22l1.62-4.98h.62s0,0,0,0h.62l1.63,4.98h-1.22l-.29-.97Zm-1.21-.89h.95l-.46-1.54h-.02l-.46,1.54Z" fill="#1a1a18"/>
      <path d="m160.85,38.73h2.14v.89h-3.29v-4.98h1.16v4.09Z" fill="#1a1a18"/>
      <path d="m166.74,37.49h-1.75v1.24h2.1v.89h-3.26v-4.98h3.26v.89h-2.11v1.07h1.75v.89Z" fill="#1a1a18"/>
    </g>
    <g>
      <path d="m4.54,38.07h.02l.78-4.3h.88l-1.24,6.04h-.78l-1.05-4.29h-.03l-1.06,4.29h-.78L.06,33.77h.89l.79,4.29h.02l1.02-4.29h.73l1.03,4.3Z" fill="#1a1a18"/>
      <path d="m8.56,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m11.85,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m16.3,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m20.66,34.71l-.36,1.16h-.46l.02-1.1v-.99h.8v.94Z" fill="#1a1a18"/>
      <path d="m23.77,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m29.43,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m32.65,39.81c-.04-.14-.06-.26-.09-.36-.02-.11-.03-.21-.04-.32-.12.22-.28.4-.47.55-.2.15-.42.22-.67.22-.4,0-.71-.12-.92-.36-.21-.24-.32-.56-.32-.98s.15-.76.45-1c.3-.24.71-.37,1.24-.37h.69v-.46c0-.27-.06-.49-.18-.64-.12-.15-.3-.23-.52-.23-.2,0-.37.07-.49.2-.12.13-.18.3-.18.51h-.8s0-.02,0-.02c-.02-.35.12-.66.41-.92.29-.27.66-.4,1.13-.4s.81.13,1.08.39.42.63.42,1.12v2.08c0,.17.01.34.03.5s.06.32.1.48h-.85Zm-1.09-.56c.23,0,.43-.07.61-.21s.29-.31.35-.52v-.8h-.7c-.26,0-.46.08-.61.25-.15.17-.22.36-.22.6,0,.21.05.37.15.5s.24.19.42.19Z" fill="#1a1a18"/>
      <path d="m36.68,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m40.09,39.26c.21,0,.38-.08.52-.24.14-.16.21-.38.21-.65h.76v.02c.02.42-.12.78-.42,1.07-.3.29-.65.44-1.07.44-.55,0-.98-.21-1.28-.63s-.45-.96-.45-1.62v-.16c0-.65.15-1.19.45-1.62s.73-.64,1.28-.64c.45,0,.81.15,1.09.46s.42.7.41,1.18v.02h-.77c0-.3-.07-.54-.2-.73-.13-.19-.31-.28-.53-.28-.32,0-.54.15-.68.46s-.21.69-.21,1.15v.16c0,.47.07.86.21,1.16.14.3.37.45.68.45Z" fill="#1a1a18"/>
      <path d="m43.46,35.92c.13-.22.28-.38.47-.5.19-.12.39-.18.62-.18.41,0,.73.15.96.45.23.3.34.76.34,1.38v2.75h-.85v-2.76c0-.41-.06-.71-.18-.89-.12-.18-.29-.27-.53-.27-.19,0-.36.05-.5.14-.14.09-.25.23-.34.39v3.38h-.85v-6.47h.85v2.59Z" fill="#1a1a18"/>
      <path d="m52.33,38.35h.02l.57-3.03h.85l-1.05,4.49h-.7l-.83-2.91h-.02l-.81,2.91h-.7l-1.05-4.49h.84l.59,2.99h.03l.82-2.99h.61l.83,3.03Z" fill="#1a1a18"/>
      <path d="m55.55,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m58.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m60.81,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.21,0,.4.05.57.14.17.1.31.23.44.41v-2.46h.84v6.47h-.7l-.09-.54c-.12.2-.28.36-.46.47s-.39.16-.62.16c-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.84.09c0,.47.07.84.21,1.12s.36.42.66.42c.19,0,.35-.05.48-.15s.24-.24.32-.42v-2.18c-.08-.18-.19-.33-.32-.44s-.29-.16-.47-.16c-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m67.02,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12s.42.43.76.43c.24,0,.44-.04.61-.11s.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m71.47,36.02l-.37-.02c-.18,0-.32.05-.44.16s-.21.26-.27.45v3.2h-.85v-4.49h.78l.06.66c.1-.24.23-.42.38-.55.15-.13.33-.2.54-.2.05,0,.1,0,.15.01.05,0,.09.02.12.04l-.1.73Z" fill="#1a1a18"/>
      <path d="m74.15,37.62c0-.72.13-1.3.39-1.73s.63-.65,1.11-.65c.23,0,.44.06.62.17.18.11.33.27.45.48l.08-.56h.71v4.5c0,.58-.15,1.02-.46,1.33-.31.31-.74.47-1.3.47-.19,0-.4-.03-.61-.09s-.41-.14-.59-.24l.14-.63c.15.09.32.15.5.2.18.05.36.07.56.07.32,0,.55-.09.7-.28.15-.19.22-.47.22-.85v-.44c-.12.17-.27.31-.43.39-.17.09-.36.13-.58.13-.47,0-.84-.2-1.1-.6-.26-.4-.39-.93-.39-1.59v-.09Zm.85.09c0,.47.07.85.21,1.12.14.28.36.42.66.42.19,0,.35-.05.48-.15s.24-.23.32-.41v-2.21c-.08-.18-.19-.33-.32-.43-.13-.11-.29-.16-.48-.16-.3,0-.52.16-.66.48s-.21.74-.21,1.25v.09Z" fill="#1a1a18"/>
      <path d="m81.15,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.06.82.17,1.01s.28.29.51.29c.21,0,.38-.05.53-.15.15-.1.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m84.26,34.21v1.12h.7v.6h-.7v2.67c0,.23.04.4.11.5.07.1.16.15.28.15.05,0,.09,0,.13-.01.04,0,.09-.02.15-.04l.09.58c-.08.04-.17.07-.27.09s-.2.04-.31.04c-.32,0-.58-.1-.75-.31-.18-.21-.27-.54-.27-.99v-2.67h-.59v-.6h.59v-1.12h.85Z" fill="#1a1a18"/>
      <path d="m89.98,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.35.49.35.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.35.19.57.19s.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m93.45,39.9c-.56,0-1-.2-1.31-.61-.32-.41-.48-.94-.48-1.6v-.2c0-.66.16-1.2.47-1.62s.73-.63,1.24-.63.9.18,1.17.54c.27.36.4.85.4,1.46v.47h-2.44c0,.46.08.83.25,1.12.17.29.42.43.76.43.24,0,.44-.04.61-.11.17-.07.33-.18.48-.32l.27.52c-.15.16-.35.29-.59.39-.25.1-.52.16-.84.16Zm-.08-4.01c-.24,0-.44.11-.58.34-.14.23-.23.52-.27.89h1.59v-.1c0-.34-.06-.61-.18-.82-.12-.21-.31-.31-.56-.31Z" fill="#1a1a18"/>
      <path d="m96.86,34.14h-.85v-.8h.85v.8Zm0,5.67h-.85v-4.49h.85v4.49Z" fill="#1a1a18"/>
      <path d="m98.88,35.32l.05.67c.13-.24.29-.42.48-.55.19-.13.41-.2.66-.2.4,0,.71.15.93.44s.33.75.33,1.37v2.76h-.84v-2.76c0-.42-.06-.72-.18-.9-.12-.17-.3-.26-.53-.26-.19,0-.36.05-.5.15-.14.1-.25.24-.33.42v3.35h-.85v-4.49h.77Z" fill="#1a1a18"/>
      <path d="m105.2,35.32l.05.61c.12-.22.28-.39.47-.51.19-.12.42-.18.67-.18s.46.07.65.21.32.34.41.62c.12-.26.28-.46.48-.61.2-.15.43-.22.7-.22.39,0,.7.16.92.48.22.32.33.8.33,1.45v2.65h-.85v-2.66c0-.47-.06-.79-.17-.98s-.29-.28-.52-.28c-.19,0-.36.07-.49.22-.13.14-.23.33-.29.57,0,.05,0,.1,0,.16,0,.05,0,.11,0,.16v2.81h-.84v-2.66c0-.45-.06-.77-.18-.97-.12-.2-.29-.29-.52-.29-.18,0-.34.05-.46.15-.13.1-.23.24-.3.41v3.36h-.85v-4.49h.78Z" fill="#1a1a18"/>
      <path d="m113.5,39.16c-.12.24-.28.42-.46.55-.19.13-.41.19-.66.19-.41,0-.73-.16-.97-.48-.23-.32-.35-.81-.35-1.48v-2.62h.85v2.63c0,.48.05.82.17,1.01.11.19.28.29.51.29.21,0,.38-.05.53-.15s.26-.24.34-.42v-3.37h.85v4.49h-.72l-.08-.66Z" fill="#1a1a18"/>
      <path d="m117.64,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.08-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58.24.22.36.49.36.82,0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63s.35.19.58.19.4-.06.52-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m121.66,38.68c0-.17-.06-.32-.17-.44-.12-.12-.34-.25-.67-.4-.46-.17-.81-.36-1.04-.57-.23-.2-.34-.47-.34-.79,0-.35.14-.64.42-.88.28-.24.64-.37,1.09-.37s.83.13,1.12.4c.28.27.42.59.41.97v.02h-.82c0-.21-.06-.4-.19-.55-.13-.15-.3-.23-.51-.23s-.38.06-.5.18c-.12.12-.18.26-.18.42,0,.17.05.31.16.4.1.1.33.22.67.38.47.17.82.36,1.06.58s.35.49.35.82c0,.37-.14.68-.43.91-.29.23-.66.35-1.13.35-.5,0-.9-.14-1.19-.42-.29-.28-.43-.61-.41-.99v-.02h.79c.01.29.09.5.25.63.15.13.34.19.57.19s.4-.06.53-.17c.13-.11.19-.26.19-.44Z" fill="#1a1a18"/>
      <path d="m124.49,39.81h-.85v-.84h.85v.84Z" fill="#1a1a18"/>
    </g>
  </g>
</svg>      
</>
    )
}