import * as React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby';

export default function Example() {
  return (
          <>
          <div className="mt-12 sm:mt-16 md:mt-0 ">
            <h2 className="text-xl font-bold  sm:text-xl sm:tracking-tight text-w-red">Einsatzorte</h2>
            <div className="mt-3">
              <p className="text-base text-gray-500">
              <Link to="../wasserschaden-ludwigsburg">Ludwigsburg</Link>, <Link to="../wasserschaden-stuttgart">Stuttgart</Link>, Heilbronn, Waiblingen, Fellbach, Böblingen inkl. Umland
              </p>
            </div>
            
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0 ">
            <h2 className="text-xl font-bold text-[#c0c0c0] sm:text-2xl sm:tracking-tight underline"><Link to="../kontakt">Kontakt</Link></h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
              Max-Planck-Str. 12<br/> 70806 Kornwestheim
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                <a href="tel:071548046092">07154 8046092</a>
                  
                </div>

              </div>
              <div className="mt-3 flex">
                <div className="flex-shrink-0">
                  <p className='h-6 w-6 text-red-600'>FAX</p>
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>07154 8046094</p>
                  
                </div>
              </div>
              <div className="mt-3 flex">
                <div className="flex-shrink-0">
                  <EnvelopeIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                <a href="mailto:info@wubssz.de?subject=Kundenanfrage über WUBSSZ.de">info@wubssz.de</a>
                </div>
              </div>
            </div>
          </div>
          </>
  )
}
