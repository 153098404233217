import React from "react";
import Nav from "./Nav";
import CenteredNav from "./CenteredNav";
import '../images/wubssz_Logo_Web.jpeg';

export default function Header(){
    return (
        
       

        <CenteredNav/>
      
       
    );
}