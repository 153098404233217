import * as React from 'react';
import { Fragment } from 'react'
import LogoOne from './LogoOne';
import LogoTwo from './LogoTwo';
import Logo from "../../assets/logo.svg"
import { Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  WrenchIcon,
  MagnifyingGlassIcon,
  SunIcon,
  FireIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { StaticImage } from 'gatsby-plugin-image';
import NotfallKontakt from '../components/NotfallKontakt';
import { Link } from 'gatsby';

const solutions = [
  {
    name: 'Leistungen',
    description: 'Übersicht über unser Diensleistungsangebot',
    href: '../unsere-leistungen',
    icon: WrenchIcon,
  },
  {
    name: 'Leckortung',
    description: 'Mit modernsten Messverfahren',
    href: '../leckageortung',
    icon:   MagnifyingGlassIcon,
  },
  { name: 'Wasserschadensanierung', description: "Schnell und professionell  ", href: '../wasserschadensanierung', icon: LifebuoyIcon },
  {
    name: 'Trocknung',
    description: 'Folgeschäden vermeiden',
    href: '../trocknung',
    icon: SunIcon,
  },
  {
    name: 'Brandschadensanierung',
    description: "",
    href: '../brandschadensanierung',
    icon: FireIcon,
  },
]
const callsToAction = [
 
  { name: 'Jetzt Anrufen: 07154 8046092', href:'tel:+4971548046092', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: LifebuoyIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkSquareIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: 'tel:+491773275876', icon: ShieldCheckIcon },
]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CenteredNav() {

  const [open, setOpen] = React.useState(false);

  return (
    <Popover className="relative bg-white">
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="../">
              <span className="sr-only">WUBSSZ - Wasser und Brandschaden Sanierungszentrale - Wenn's rasch wieder gut sein muss.</span>
        <div className=''>

        <LogoOne/>
        </div>
        {/* <LogoTwo/> */}
            </a>
          </div>
          <div className=' sm:hidden  border border-transparent  text-sm font-medium text-white justify-self-end grow grid '><div className='bg-[#034978] p-1 rounded-md justify-self-end w-24 mr-4'><a
                  href="tel:071548046092"
                  className=" p-0 flex  flex-nowrap "
                >
                   <PhoneIcon className='h-8 w-8  p-[2px] text-white text-gray-400'/>
                  <div className='text-center leading-3 py-1'>
                    <span className='text-[10px] text-white font-weight-bold '>07154-<br/>8046092</span>
                    
                    </div>
                 
                </a></div></div>

          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#034978]">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#034978] focus:ring-offset-2'
                    )}
                  >
                    <span>Leistungen</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-50 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-start  p-3 hover:bg-gray-80"
                              activeClassName="bg-gray-50 border-l-4 border-[#0a4778]"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-[#034978]" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500 no-underline decoration-[transparent]">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                              >
                                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link to="../wasserschadensanierung" className="text-base font-medium text-gray-500 hover:text-gray-900" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
              Wasserschaden
            </Link>
            <Link to="../leckageortung" className="text-base font-medium text-gray-500 hover:text-gray-900" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
              Leckortung
            </Link>
            <Link href="../trocknung" className="md:hidden lg-inline text-base font-medium text-gray-500 hover:text-gray-900" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
              Trocknung
            </Link>

            <Link href="../jobs" className="text-base font-medium text-gray-500 hover:text-gray-900" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]"
            >
              Jobs
            </Link>

           
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <button className="font-semibold bg-red-600 rounded-full p-3 lg:px-6 lg:py-3 text-gray-800 " onClick={()=> setOpen(true)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f6d2d2" className="align-top w-6 h-6 inline md:mr-0 lg:mr-2 text-gray-600">
  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
</svg>
<span className='md:hidden lg:inline text-red-100'>Kontakt</span></button>
            {/* <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a>
            <a
              href="#"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#034978] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#034978]"
            >
              Sign up
            </a> */}
            <NotfallKontakt 
            open={open}
            setOpen= {setOpen}/>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="z-50 absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <LogoOne/>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#034978]">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]"
                    >
                      <item.icon className="h-6 w-6 flex-shrink-0 text-[#034978]" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link to="../kontakt" className="text-base font-medium text-gray-900 hover:text-gray-700" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
                  Kontakt
                </Link>
                <Link to="../impressum" className="text-base font-medium text-gray-900 hover:text-gray-700" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
                  Impressum
                </Link>

                <Link to="../jobs" className="text-base font-medium text-gray-900 hover:text-gray-700" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
                  Jobs
                </Link>
                <Link to="../datenschutzerklaerung" className="text-base font-medium text-gray-900 hover:text-gray-700" activeClassName="underline underline-offset-8 decoration-4 decoration-[#0a4778]">
                  Datenschutz
                </Link>
                {/* {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))} */}
              </div>
              <div>
                <a
                  href="tel:071548046092"
                  className="relative flex w-full justify-center	 rounded-md border border-transparent bg-[#034978] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#034978]"
                ><div className="flex items-center px-4 relative -left-4">
                  <PhoneIcon className='h-6 mr-2'/><span className='text-xl'>07154 8046092</span>

                </div>
                </a>
               
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}