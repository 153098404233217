import * as React from 'react';



  
  export default function ZeitContent() {
    return (
      <div>
        <br/>
        <div className="ml-3 text-sm text-gray-500"><p className='mb-4'>Geschäftszeiten:</p></div>
        <table className='table-auto'>
          <tr>
          <td className="ml-3 text-sm text-w-red text-left">Montag: </td><td className='pl-2 text-sm text-gray-500'>9:00 - 16:00</td>
          </tr><tr>
          <td className="ml-3 text-sm text-w-red text-left">Dienstag: </td><td className='pl-2 text-sm text-gray-500'>9:00 - 16:00</td>
          </tr><tr>
          <td className="ml-3 text-sm text-w-red text-left">Mittwoch: </td><td className='pl-2 text-sm text-gray-500'>9:00 - 16:00</td>
          </tr><tr>
          <td className="ml-3 text-sm text-w-red text-left">Donnerstag: </td><td className='pl-2 text-sm text-gray-500'>9:00 - 16:00</td>
          </tr>
          <tr>
          <td className="ml-3 text-sm text-w-red text-left">Freitag: </td><td className='pl-2 text-sm text-gray-500'>9:00 - 12:00</td>
          </tr>
        </table>
       
    </div>

    
    )
  }
  




