import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Job(){
    return (
      <Link to="/jobs/">

        <div className="relative rounded-full w-48 h-48 neon border-2 border-gray-200  p-2 rotate-6">
      <div className="relative top-6 text-center">
        <p className="text-center   text-md font-bold">KOLLEGEN<br />GESUCHT!</p>
        <p className="text-center">Jetzt bewerben und</p>
        <StaticImage src="../images/logo-sm.png" width={100} className="mx-auto text-center"/>
        <span className="text-center">'er</span>
        <p className="text-center">werden</p>
      </div>
    </div>
      </Link>
    )
}

