import * as React from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PhoneArrowUpRightIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import LogoOne from './LogoOne';
import ZeitContent from './zeiten';
export default function NotfallKontakt({ open, setOpen, ...data}) {
//   const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-medium text-gray-400 text-center">Schnellkontakt zu WUBBSZ:</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#034978] focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Schließen</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6">
                      <div className="">
                            <div className="flex justify-center w-2/5 mx-auto">
                              <LogoOne />
                            </div>
                          <p className='mt-8 text-gray-600 text-sm text-center'>Mit Klick auf grünen Button direkt vom Handy aus anrufen:</p>
                          <a href='tel:071548046092'>
                          <div className='bg-green-600 rounded-xl p-6 mx-28 my-8'>
                            <PhoneArrowUpRightIcon className='text-white'/>
                           
                          </div>
                            </a>  
                          {/* <div>
                            <br/>
                            <div className='text-center'>
                            <p className='text-lg font-semibold text-gray-600'><span>Tel:</span> 07154-8046092 </p>
                            <br/>
                            <p className='text-lg font-semibold text-gray-600'>
                              Email: <a href="mailto:info@wubssz.de">info@wubssz.de</a>
                              <br/><br/>
                              <p className='text-gray-600'>Max-Planck-Str. 12<br/> 70806 Kornwestheim</p>
                            </p>

                            </div>
                            <br/>
                            <br/>
                            


                          </div> */}
                                    <div className="mt-12 sm:mt-16 md:mt-0 text-center">
            <h2 className="text-xl font-bold text-gray-600 sm:text-2xl sm:tracking-tight">WUBSSZ</h2>
            <div className="mt-3 text-center">
              <p className="text-lg text-gray-500">
              Max-Planck-Str. 12<br/> 70806 Kornwestheim
              </p>
            </div>
            <div className="mt-9 flex flex-col justify-center">
              <div className="flex justify-center">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>07154 8046092</p>
                  
                </div>

              </div>
              <div className="mt-3 flex justify-center">
                <div className="flex-shrink-0">
                  <p className='h-6 w-6 text-red-600'>FAX</p>
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>07154 8046094</p>
                  
                </div>
              </div>
              <div className="mt-3 flex justify-center">
                <div className="flex-shrink-0">
                  <EnvelopeIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                <a href="mailto:info@wubssz.de?subject=Kundenanfrage über WUBSSZ.de">info@wubssz.de</a>
                </div>
              </div>
              <div className="mt-3 flex justify-center">
                <br/>
                          <ZeitContent/>
              </div>
            </div>
          </div>


                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
